.login-admin .login-content .user-check-pin {
    width: 100%;
}


.login-admin .login-content .user-check-pin button{
    width: 100%;
    border-radius: 6px;
    border: none;
    outline: none;
    height: 50px;
    margin-top: 10px;
    background-color: #5fbb4f;
    color: #fff;
    font-size: 15px;
    transition: 0.5s;
}
.login-admin .login-content .user-check-pin button:hover{
    background-color: #418735;

}

.login-form .news-content {
    width: 100%;
    background-color: #3f51b533;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #3f51b578;
}

.login-form .news-content h3{
    font-size: 19px;
    font-weight: 600;
    color: #000;
}

.login-form .news-content>div{
    display: flex;
    flex-direction: column;
    padding: 30px;
    gap: 8px;
}

.login-form .news-content>div h4{
    font-size: 16px;
    display: list-item;
}


