

.home-page .state{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;
    background-color: #fff;
    border: 1px solid #0000002b;
    border-radius: 7px;
    margin-bottom: 25px;
    margin-top: 25px;
}

.home-page .state>div{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 17px;
}

.home-page .state>div i{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    background-color: #3f51b5;
    color: #fff;
    font-size: 34px;
    border-radius: 50%;
}

.home-page .state>div h1{
    font-size: 40px;
    color: #000;
    font-weight: 600;
}

.home-page .state h2 {
    font-size: 20px;
    margin-top: 16px;
    font-weight: 500;
    color: #9b9b9b;
    font-weight: 400;
}



.home-page .news-content{
    width: 100%;
    background-color: #3f51b533;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #3f51b578;
    margin-bottom: 15px;
    margin-top: 15px;
}

.home-page .news-content h3{
    font-size: 19px;
    font-weight: 600;
    color: #000;
}

.home-page .news-content>div{
    display: flex;
    flex-direction: column;
    padding: 30px;
    gap: 8px;
}

.home-page .news-content>div h4{
    font-size: 16px;
    display: list-item;
}
