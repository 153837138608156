.login-admin{
    background-color: #eff0f4;
    position: relative;
    inset: 0;
    width: 100%;
    height: 100vh;
}


.login-admin .login-content{
    width: 47%;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-height: 600px;
    border-radius: 10px;
    padding: 25px;
    border: 1px solid #0000001f;
}


.login-admin .login-content .err-msg h2{
    font-size: 16px;
    font-weight: 400;
}



.login-admin .login-content .logo{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-admin .login-content .logo img{
    width: 200px ;
    height: 65px;
    object-fit: contain;
}


.login-admin .login-content .logo div{
    
}

.login-admin .login-content .logo div h1{
    font-size: 20px;
    font-weight: 400;
    color: #000;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    font-weight: 500;
}

.login-admin .login-content .logo div h1 i{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    background-color: #dc5dd0;
    color: #fff;
    border-radius: 50%;
    padding: 8px;
    font-size: 13px;
}


.login-admin .login-content .logo div h2{
    text-align: center;
    font-size: 17px;
    color: #9c9c9c;
    font-weight: 400;
}


.login-admin .login-content .login-form{
    margin-top: 30px;
}

.login-admin .login-content .login-form form{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    width: 100%;
}


.login-admin .login-content .login-form form .input{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    width: 100%;
    position: relative;
}

.login-admin .login-content .login-form form .input label{
    font-size: 14px;
    color: #000;
    font-weight: 400;
}

.login-admin .login-content .login-form form .input input{
    height: 50px;
    border-radius: 5px;
    border: none;
    outline: none;
    width: 100%;
    border: 1px solid #aeaeae;
    font-size: 16px;
    color: #000;
    padding-left: 15px;
    padding-right: 30px;
}

.login-admin .login-content .login-form form .input button{
    position: absolute;
    top: 53px;
    right: 15px;
    transform: translateY(-50%);
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 20px;
}



.login-admin .login-content .login-form form>button{
    width: 100%;
    border-radius: 6px;
    border: none;
    outline: none;
    height: 50px;
    margin-top: 10px;
    background-color: #5fbb4f;
    color: #fff;
    font-size: 15px;
    transition: 0.5s;
}

.login-admin .login-content .login-form form>button:hover{
    background-color: #418735;
}

.login-admin .login-content .login-form form>a{
    font-size: 15px;
    font-weight: 400;
    color: #000;
    margin-top: 26px;
}


.login-admin .login-content .err-msg {
    background-color: #ff00001f;
    color: #de0000;
    padding: 15px;
    border-radius: 6px;
    height: 55px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 19px;
    border: 1px solid #de0000;
    transition: 0.5s;
}

.login-admin .login-content .err-msg.active{
    display: block;
}

@media(max-width:950px){
    .login-admin .login-content {
        width: 99%;
        background-color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-height: 600px;
        border-radius: 10px;
        padding: 25px;
        border: 1px solid #0000001f;
    }

}